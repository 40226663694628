import { Controller } from "@hotwired/stimulus";
import { useTextarea, useSidePanelContent, useDialog } from "../../behaviors";

export default class WorkStreamFormController extends Controller {
  static targets = [
    "nameFieldElement",
    "nameHelpText",
    "nameErrorList",
    "nameFieldWrapper",
    "dialogWrapper",
    "dialogOverlay",
  ];

  static classes = [
    "errorFieldWrapper",
    "errorHelpText",
    "hidden",
    "textareaValidEmpty",
  ];

  connect() {
    useTextarea(
      this,
      "name",
      "nameFieldElementTarget",
      "nameHelpTextTarget",
      "nameFieldWrapperTarget",
      "errorFieldWrapperClass",
      "errorHelpTextClass",
    );
    useSidePanelContent(this, { elementToFocus: this.nameFieldElementTarget });
    useDialog(this);

    this.nameFieldElementTarget.required = false;
  }

  clickOutside(evt) {
    this.openDialog(evt);
  }

  shouldBypassDialog() {
    const currentValue = this.nameFieldElementTarget.value;
    const { initialValue } = this.nameFieldElementTarget.dataset;
    const bypassDialog =
      (initialValue === "" && this.numberOfCharactersFor_name === 0) ||
      initialValue === currentValue;
    return bypassDialog;
  }
}
