/* eslint import/prefer-default-export: "off" */
import { readJsonScriptValue } from "../utils/json_script";

const amplitudeApiKey = readJsonScriptValue("settings-amplitude-api-key");
const userIdEmailAddress = readJsonScriptValue("user-email-address", {
  defaultValue: null,
});
const version = readJsonScriptValue("settings-source-version");

let instance = null;

if (amplitudeApiKey !== undefined) {
  instance = window.amplitude.getInstance();
  instance.init(amplitudeApiKey, userIdEmailAddress, {
    // serverZone: "EU",
    // serverZoneBasedApi: true,
  });
  instance.setVersionName(version);
}

const extendEventProperties = (properties) => ({
  ...properties,
  context: "Web Application",
});

export const logEvent = (evt, properties = {}) => {
  const extendeProperties = extendEventProperties(properties);
  if (instance === null) {
    // console.log(evt, extendeProperties); /* eslint-disable-line no-console */
    return;
  }
  instance.logEvent(evt, extendeProperties);
};

logEvent("page.load", { url: window.location.href });
